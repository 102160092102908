<template>
  <v-expansion-panels
      class="pa-0 ma-0"
      :value="true"
  >
    <v-expansion-panel class="pa-0 ma-0">
      <v-expansion-panel-header class="card-header-tab card-header">
        <v-card-title class="">
          <div :class="[this.$vuetify.theme.dark ? 'white--text' : '']" class="card-header-title font-size-lg text-capitalize font-weight-normal">
            <span>{{ $t('generic.lang_prices') }}
              <span class="ml-5 badge rounded-xl badge-primary">{{ priceList.length }}</span>
            </span>
          </div>

          <div class="btn-actions-pane-right actions-icon-btn"></div>
          <v-progress-linear v-if="loadingPriceSegments" indeterminate />
        </v-card-title>

        <v-tooltip top nudge-right="400">
          <template v-slot:activator="{ on, attrs }">
            <v-icon class="mr-5 justify-end align-content-end text-right" color="primary" dark size="20" v-bind="attrs" v-on="on">
              mdi-information
            </v-icon>
          </template>
          <span>
            {{ $t("eventbee.lang_pricesListTooltip") }}
          </span>
        </v-tooltip>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-row>
          <v-col
              class=""
              cols="12"
          >
            <v-data-table
                :headers="headers"
                :items="priceList"
                hide-default-footer
                :items-per-page="-1"
                :no-data-text="$t('generic.lang_noEntriesFound')"
            >
              <template v-slot:header.Action="{}">
                <v-tooltip left>
                  <template v-slot:activator="{ on }">
                    <v-btn
                        @click="addPrice()"
                        class="mx-auto"
                        color="primary"
                        fab
                        icon
                        small
                        v-on="on"
                    >
                      <v-icon>add</v-icon>
                    </v-btn>
                  </template>
                  <span>
                    {{ $t('generic.lang_addPrice') }}
                  </span>
                </v-tooltip>
              </template>
              <template v-slot:header.priceSegmentID="{ header }">
                <div class="d-flex">
                  <div>{{ header.text }}</div>

                  <v-tooltip top nudge-left="100">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon class="ml-1" color="primary" dark size="20" v-bind="attrs" v-on="on">
                        mdi-information
                      </v-icon>
                    </template>
                    <span>
                      {{ $t("eventbee.lang_priceSegmentIdTooltip") }}
                    </span>
                  </v-tooltip>
                </div>
              </template>
              <template v-slot:header.price="{ header }">
                <div class="d-flex">
                  <div>{{ header.text }}</div>

                  <v-tooltip top nudge-left="100">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon class="ml-1" color="primary" dark size="20" v-bind="attrs" v-on="on">
                        mdi-information
                      </v-icon>
                    </template>
                    <span>
                      {{ $t("eventbee.lang_pricesPriceTooltip") }}
                    </span>
                  </v-tooltip>
                </div>
              </template>
              <template v-slot:header.standard="{ header }">
                <div class="d-flex">
                  <div>{{ header.text }}</div>

                  <v-tooltip top nudge-left="100">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon class="ml-1" color="primary" dark size="20" v-bind="attrs" v-on="on">
                        mdi-information
                      </v-icon>
                    </template>
                    <span>
                      {{ $t("eventbee.lang_pricesStandardTooltip") }}
                    </span>
                  </v-tooltip>
                </div>
              </template>
              <template v-slot:header.requiresBooking="{ header }" class="d-flex">
                <div class="d-flex">
                  <div>{{ header.text }}</div>

                  <v-tooltip top nudge-left="100">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon class="ml-1" color="primary" dark size="20" v-bind="attrs" v-on="on">
                        mdi-information
                      </v-icon>
                    </template>
                    <span>
                      {{ $t("eventbee.lang_pricesRequiresBookingTooltip") }}
                    </span>
                  </v-tooltip>
                </div>
              </template>
              <template v-slot:header.max="{ header }">
                <div class="d-flex">
                  <div>{{ header.text }}</div>

                  <v-tooltip top nudge-left="100">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon class="ml-1" color="primary" dark size="20" v-bind="attrs" v-on="on">
                        mdi-information
                      </v-icon>
                    </template>
                    <span>
                      {{ $t("eventbee.lang_pricesMaxTooltip") }}
                    </span>
                  </v-tooltip>
                </div>
              </template>

              <template v-slot:item.priceSegmentID="{ item }">
                <v-select
                    @change="setPriceId($event,item)"
                    :items="filteredPriceSegments(item.priceSegmentID)"
                    :rules="[rules.required]"
                    class="pt-3"
                    dense
                    item-text="name"
                    item-value="id"
                    hide-details
                    :loading="loadingPriceSegments"
                    outlined
                    style="min-width: 130px; width: 100%"
                    v-model="item.priceSegmentID"
                ></v-select>
              </template>

              <template v-slot:item.price="{ item }">
                <v-text-field
                    :rules="[rules.required]"
                    class="pt-3"
                    type="number"
                    dense
                    outlined
                    hide-details
                    v-model="item.price"
                ></v-text-field>
              </template>

              <template v-slot:item.max="{ item }">
                <v-text-field
                    class="pt-3"
                    type="number"
                    dense
                    outlined
                    :min="0"
                    hide-details
                    v-model="item.max"
                ></v-text-field>
              </template>

              <template v-slot:item.standard="{ item }">
                <v-checkbox
                  dense
                  outlined
                  hide-details
                  v-model="item.standard"
                  @change="setStandard(item)"
                />
              </template>

              <template v-if="requiresBooking" v-slot:item.requiresBooking="{ item }">
                <v-checkbox
                  dense
                  outlined
                  hide-details
                  v-model="item.requiresBooking"
                />
              </template>

              <template v-slot:item.Action="{ item }">
                <v-col cols="12">
                  <button
                      @click="removePrice(item)"
                      class="btn btn-danger btn-block"
                      type="button"
                  >
                    <v-icon color="white">delete</v-icon>
                  </button>
                </v-col>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import {ENDPOINTS} from "@/config";
import validation from "@/mixins/validation/validation";
import { Events } from '@/plugins/events';

export default {
  name: "TicketsPriceListComponent",
  props:{
    value:{
      type:Array,
      default:()=>[],
    },
    requiresBooking: {
      type: Boolean,
      default: true,
    },
  },
  mixins:[validation],
  data() {
    return {
      priceList:[],
      priceSegments:[],
      loadingPriceSegments: false,
    }
  },
  watch: {
    priceList:{
      handler(v){
        this.$emit('input',v)
      },
      deep:true,
    },
    value:{
      handler(v){
        this.priceList = v;
        this.$forceUpdate();
      },
      deep:true,
    },
  },
  computed: {
    filteredPriceSegments(){
      return segmentID=>{
        let selectedIds = segmentID ? this.priceList.filter(price => price.id !== null && price.id !== segmentID).map(price => price.id) : this.priceList.filter(price => price.id !== null).map(price => price.id);
        return this.priceSegments.filter(price => !selectedIds.includes(price.id))
      }
    },
    headers() {
      return [
        {
          text: this.$t('eventbee.lang_priceSegment'),
          value: 'priceSegmentID',
          sortable: false,
        },
        {
          text: this.$t('generic.lang_cashier_price'),
          value: 'price',
          sortable: false,
        },
        {
          text: this.$t('generic.lang_standard'),
          value: 'standard',
          sortable: false,
        },
        {
          text: this.$t('generic.lang_requiredBooking'),
          value: 'requiresBooking',
          sortable: false,
          align: 'center'
        },
        {
          text: this.$t('eventbee.lang_maxTicket'),
          value: 'max',
          sortable: false,
          align: 'center'
        },
        {
          text: '',
          value: 'Action',
          align: 'center',
          sortable: false,
        },
      ].filter(item=> this.requiresBooking || item.value !== "requiresBooking" )
    },
  },
  methods: {
    setPriceId(value, priceObj) {
      priceObj.id = value;
      this.$forceUpdate();
    },
    addPrice() {
      this.priceList.push({
        id: null,
        priceSegmentID: null,
        price: 0,
        standard: false,
        requiresBooking: this.requiresBooking? false : undefined,
        max: 0,
      });
    },
    removePrice(item) {
      let index = this.priceList.indexOf(item);
      this.priceList.splice(index, 1);
    },
    setStandard(target) {
      this.priceList.forEach((segment) => {
        segment.standard = false;
      });

      target.standard = !target.standard;
    },
    loadPriceSegments() {
      this.loadingPriceSegments = true;
      this.axios
          .post(ENDPOINTS.EVENTBEE.SETTINGS.PRICESEGMENTS.GET)
          .then((res) => {
            if (res.data.status) {
              this.priceSegments = res.data.data;
            } else {
              Events.$emit('showSnackbar', {
                message: this.$t('generic.lang_errorOccurred'),
                color: 'error',
              });
            }
          })
          .catch((err) => {
            Events.$emit('showSnackbar', {
              message: this.$t('generic.lang_errorOccurred'),
              color: 'error',
            });
          })
          .finally(() => {
            this.loadingPriceSegments = false;
          });
    },
  },
  mounted() {
    this.loadPriceSegments();
    if(this.value === null || (this.value && this.value.length<1)) {
      this.priceList.push({
        id: null,
        priceSegmentID: null,
        price: 0,
        standard: false,
        requiresBooking: this.requiresBooking? false : undefined,
        max: 0,
      })
    }else{
      this.priceList = this.value;
    }
  }
}
</script>

<style scoped>

</style>